// Redux
import { useDispatch, useSelector } from 'react-redux'
import { challengeNewPassword } from '../authSlice'
// Bootstrap
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
// Formik
import { useFormik } from 'formik'
import FormGroup from 'react-bootstrap/esm/FormGroup'

export function ChallengeNewPasswordRequired() {
  // Dispatch to issue redux state middleware events
  const dispatch = useDispatch()

  // Retrieve values from state with selectors
  const submitting = useSelector((state) => state.auth.submitting)

  const validate = values => {
    const errors = {};

    if (!values.password) {
      errors.password = 'Required';
    }

    return errors;
  };

  // Set up form handling
  const formik = useFormik({
    initialValues: {
    },
    validate: validate,
    onSubmit: values => {
      dispatch(challengeNewPassword(values))
    },
  });

  return (
    <Form noValidate onSubmit={formik.handleSubmit}>
      <Form.Group className="mb-3" controlId="password">
        <Form.Label>New Password</Form.Label>
        <Form.Control
          name="password"
          type="password"
          placeholder="password"
          onChange={formik.handleChange}
          />
        {formik.errors.password ? <div>{formik.errors.password}</div> : null}
      </Form.Group>
      <FormGroup>
        <Button
          variant="primary"
          type="submit"
          disabled={submitting}
        >
          { submitting ? 'Changing password...' : 'Change password' }
        </Button>
      </FormGroup>
    </Form>
  )
}
