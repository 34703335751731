import React from 'react'
import Container from 'react-bootstrap/esm/Container';
// Redux
import { useSelector } from 'react-redux';
import { Header } from '../features/nav/Header';
// Bootstrap

export function Default() {
  // Retrieve values from state with selectors
  const path = useSelector((state) => state.nav.path)

  var title = 'Dashboard';

  switch (path) {
    case '/invoices':
      title = 'Invoices';
      break;
    case '/projects':
      title = 'Projects';
      break;
    default:
      break;
  }

  return (
    <>
      <Header>
      </Header>
      <Container>
        <h1>{title}</h1>
      </Container>
    </>
  )
}
