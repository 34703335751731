// Redux
import { useDispatch, useSelector } from 'react-redux'
import { mfaVerify } from '../authSlice'
// Bootstrap
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
// Formik
import { useFormik } from 'formik'
import FormGroup from 'react-bootstrap/esm/FormGroup'
// QR
var QRCode = require('qrcode.react');

const mfaIssuer = process.env.REACT_APP_MFA_ISSUER

export function ChallengeMfaSetup() {
  // Dispatch to issue redux state middleware events
  const dispatch = useDispatch()

  // Retrieve values from state with selectors
  const submitting = useSelector((state) => state.auth.submitting)
  const mfaSetupCode = useSelector((state) => state.auth.mfaSetupCode)
  const username = useSelector((state) => state.auth.username)

  const validate = values => {
    const errors = {};

    if (!values.token) {
      errors.token = 'Required';
    }

    return errors;
  };

  // Set up form handling
  const formik = useFormik({
    initialValues: {
    },
    validate: validate,
    onSubmit: values => {
      dispatch(mfaVerify(values))
    },
  });

  return (
    <>
      { mfaSetupCode ?
        <>
          <p>Scan the following QR code with an app or enter the code manually:</p>
          <p><pre>{ mfaSetupCode }</pre></p>
          <QRCode value={"otpauth://totp/" + username + "?secret=" + mfaSetupCode + "&issuer=" + mfaIssuer }/>
        </>
        :
        <p>Loading QR code</p>
      }
      <br />
      <Form noValidate onSubmit={formik.handleSubmit}>
        <Form.Group className="mb-3" controlId="password">
          <Form.Label>Time-based token</Form.Label>
          <Form.Control
            name="token"
            type="password"
            placeholder="MFA Token"
            onChange={formik.handleChange}
          />
          {formik.errors.password ? <div>{formik.errors.password}</div> : null}
        </Form.Group>
        <FormGroup>
          <Button
            variant="primary"
            type="submit"
            disabled={submitting}
          >
            { submitting ? 'Submitting token...' : 'Submit token' }
          </Button>
        </FormGroup>
      </Form>
    </>
  )
}
