import React from 'react'
// Redux
import { useSelector } from 'react-redux'
// Bootstrap
import Alert from 'react-bootstrap/Alert'
import { Login } from './forms/login'
import { ChallengeNewPasswordRequired } from './forms/challengeNewPasswordRequired';
import { ChallengeMfaSetup } from './forms/challengeMfaSetup';
import { ChallengeMfa } from './forms/challengeMfa';

export function Auth() {
  // Retrieve values from state with selectors
  const authErrorCode = useSelector((state) => state.auth.errorCode)
  const authErrorMessage = useSelector((state) => state.auth.errorMessage)
  const challenge = useSelector((state) => state.auth.challenge)

  // Determine the form and alert message to use based on state
  var form = <Login></Login>
  var alertVariant = 'primary'
  var alertMessage = 'Sign in to start your session'

  // If a challenge has occurred, display the appropriate form and alert message
  // Overridden if an exception has occurred later on
  switch (challenge) {
    case 'NEW_PASSWORD_REQUIRED':
      form = <ChallengeNewPasswordRequired></ChallengeNewPasswordRequired>
      alertVariant = 'warning'
      alertMessage = 'A new password is required'
      break;
    case 'MFA_SETUP':
      form = <ChallengeMfaSetup></ChallengeMfaSetup>
      alertVariant = 'warning'
      alertMessage = 'Multi-factor authentication (MFA) software token set up required. Scan QR code and generate a new token.'
      break;
    case 'SOFTWARE_TOKEN_MFA':
      form = <ChallengeMfa></ChallengeMfa>
      alertVariant = 'primary'
      alertMessage = 'Provide a Multi-factor authentication (MFA) software time-based one time password (TOTP) token from an app.'
      break;
    default:
      break;
  }

  // If an exception has ocurred, display an error
  if (authErrorCode) {
    alertVariant = 'danger'
    alertMessage = authErrorMessage
  }

  return(
    <div className="container">
      <div className="vh-100 d-flex justify-content-center row">
        <div className="align-self-center col-lg-4 col-sm-12">
          { form }
          <br />
          <Alert variant={ alertVariant }>
            { alertMessage }
          </Alert>
        </div>
      </div>
    </div>
  )
}
