// Redux toolkit features
import { Auth } from "./features/auth/Auth";
import { Default } from "./layouts/default";
import { useSelector } from 'react-redux'

function App() {
  // Fetch authenticated state from redux toolkit store
  const authenticated = useSelector((state) => state.auth.authenticated)

  return (
    // Render layout if authenticated, otherwise show auth
    authenticated ? <Default></Default> : <Auth></Auth>
  );
}

export default App;
