import { createSlice } from '@reduxjs/toolkit'
import Amplify, { Auth } from 'aws-amplify';

// https://docs.amplify.aws/lib/auth/start/q/platform/js/#re-use-existing-authentication-resource
Amplify.configure({
  Auth: {
    region: process.env.REACT_APP_AMPLIFY_AUTH_REGION,
    userPoolId: process.env.REACT_APP_AMPLIFY_AUTH_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_AMPLIFY_AUTH_CLIENT_ID,
    mandatorySignIn: false,
    cookieStorage: {
      domain: process.env.REACT_APP_AMPLIFY_COOKIE_DOMAIN || "127.0.0.1",
      path: '/',
      expires: 365,
      sameSite: process.env.REACT_APP_AMPLIFY_COOKIE_SAMESITE || "strict",
      secure: process.env.REACT_APP_AMPLIFY_COOKIE_SECURE || true,
    },
  }
});

// Initial redux state
const initialState = {
  submitting: false,
  authenticated: false,
  errorMessage: null,
  errorCode: null,
  challenge: null,
  mfaSetupCode: null,
  session: null,
  username: null,
}

// Slice
export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    submitting: (state) => {
      state.submitting = true
    },
    submitted: (state) => {
      state.submitting = false
    },
    loginSuccess: (state) => {
      state.authenticated = true
    },
    logoutSuccess: (state) => {
      state.authenticated = false
    },
    setErrorCode: (state, action) => {
      state.errorCode = action.payload
    },
    setErrorMessage: (state, action) => {
      state.errorMessage = action.payload
    },
    challenged: (state, action) => {
      state.challenge = action.payload
    },
    sessionCreated: (state, action) => {
      state.session = action.payload
    },
    setUsername: (state, action) => {
      state.username = action.payload
    },
    setMfaSetupCode: (state, action) => {
      state.mfaSetupCode = action.payload
    }
  }
})

// Action creators are generated for each case reducer function
export const {
  submitted,
  submitting,
  loginSuccess,
  logoutSuccess,
  setErrorCode,
  setErrorMessage,
  challenged,
  sessionCreated,
  setUsername,
  setMfaSetupCode,
} = authSlice.actions

// User object (not stored in state). Mutates as user operations are performed.
var user = null

const handleAuthError = async function(dispatch, error) {

  // Extract errors and update state
  dispatch(setErrorCode(error.code))
  dispatch(setErrorMessage(error.message))

  // Release submit state
  dispatch(submitted())
}

const handleAuthEvent = async function(dispatch) {

  // Extract username
  if ('username' in user) {
    dispatch(setUsername(user.username))
  }

  if ('signInUserSession' in user && user.signInUserSession !== null) {
    // User has been signed in, clear any existing challenge state and continue
    dispatch(challenged(null))
    dispatch(sessionCreated(null))
    // Clear any errors
    dispatch(setErrorCode(null))
    dispatch(setErrorMessage(null))
    // Set login success state
    dispatch(loginSuccess())
  }
  else if ('challengeName' in user) {
    // Check user object has a challenge, where a 200 OK was received but a challenge was also requested
    // https://docs.aws.amazon.com/cli/latest/reference/cognito-idp/respond-to-auth-challenge.html
    // Note that the challenge will remain in the user object even though sign in was successful

    // Clear any existing errors
    dispatch(setErrorCode(null))
    dispatch(setErrorMessage(null))

    // Set state with challenge details
    dispatch(challenged(user.challengeName))
    dispatch(sessionCreated(user.Session))

    // perform any additional actions needed by specific challenges
    switch (user.challengeName) {

      case 'NEW_PASSWORD_REQUIRED':
        // Clear any errors
        dispatch(setErrorCode(null))
        dispatch(setErrorMessage(null))
        break;

      case 'MFA_SETUP':
        // Clear any errors
        dispatch(setErrorCode(null))
        dispatch(setErrorMessage(null))
        // To complete MFA setup, need to also fetch an MFA setup code
        // This becomes part of the QR code and may be entered manually
        // https://docs.amplify.aws/lib/auth/mfa/q/platform/js/#setup-totp
        const mfaSetupCode = await Auth.setupTOTP(user)
        dispatch(setMfaSetupCode(mfaSetupCode))
        break

      case 'SOFTWARE_TOKEN_MFA':
        // Clear any errors
        dispatch(setErrorCode(null))
        dispatch(setErrorMessage(null))
        break;

      default:
        break;
    }
  } else {
    // Not challenged, clear any existing challenge state and continue to log in
    dispatch(challenged(null))
    dispatch(sessionCreated(null))
    // Clear any errors
    dispatch(setErrorCode(null))
    dispatch(setErrorMessage(null))
    // Set login success state
    dispatch(loginSuccess())
  }

  // Release submit state
  dispatch(submitted())
}

/**
 * Thunk middleware to perform async login.
 * Errors may be for disabled user or invalid credentials.
 *
 * @param {*} credentials username and password object
 */
 export const login = credentials => async dispatch => {
  dispatch(submitting())
  try {
    user = await Auth.signIn(credentials.username, credentials.password);
    await handleAuthEvent(dispatch)
  } catch (error) {
    await handleAuthError(dispatch, error)
  }
};

/**
 * Thunk middleware to perform async logout.
 * @param {*} credentials username and password object
 */
export const logout = credentials => async dispatch => {
  try {
    user = await Auth.signOut();
    dispatch(logoutSuccess())
  } catch (error) {
    await handleAuthError(dispatch, error)
  }
};

/**
 * Thunk middleware to respond to new password challenge.
 * Updated user password.  Errors may be around password complexity policy
 *
 * @param {*} credentials username and password object
 */
export const challengeNewPassword = credentials => async (dispatch) => {
  dispatch(submitting())
  try {
    user = await Auth.completeNewPassword(user, credentials.password)
    await handleAuthEvent(dispatch)
  } catch (error) {
    await handleAuthError(dispatch, error)
  }
};

/**
 * Thunk middleware to check a token is valid for a user
 * Errors may occur when supplied token fails validation
 * https://docs.amplify.aws/lib/auth/mfa/q/platform/js/#setup-totp
 *
 * @param {*} credentials token
 */
 export const mfaVerify = credentials => async (dispatch) => {
  dispatch(submitting())
  try {
    // Send along the new code to associate device
    user = await Auth.verifyTotpToken(user, credentials.token)
    await handleAuthEvent(dispatch)
  } catch (error) {
    await handleAuthError(dispatch, error)
  }
};

/**
 * Thunk middleware to check a token is valid for a user
 * Errors may occur when supplied token fails validation
 * https://docs.amplify.aws/lib/auth/mfa/q/platform/js/#setup-totp
 *
 * @param {*} credentials token
 */
 export const mfaSignIn = credentials => async (dispatch) => {
  dispatch(submitting())
  try {
    user = await Auth.confirmSignIn(user, credentials.token, 'SOFTWARE_TOKEN_MFA')
    await handleAuthEvent(dispatch)
  } catch (error) {
    await handleAuthError(dispatch, error)
  }
};

/**
 * Thunk middleware to check user session.
 * Useful on initial load to check for an existing cookie session.
 * https://aws-amplify.github.io/amplify-js/api/classes/authclass.html#currentuserinfo
 *
 */
 export const checkCurrentUser = () => async (dispatch) => {
  try {
    user = await Auth.currentUserPoolUser()
    await handleAuthEvent(dispatch)
  } catch (error) {
    console.log(error)
  }
};

// Export redux selectors
export const selectSubmitting = state => state.auth.submitting;
export const selectErrorCode = state => state.auth.errorCode;
export const selectErrorMessage = state => state.auth.errorMessage;

// Export reducer
export default authSlice.reducer
