import React from 'react'
// Redux
import { logout } from '../auth/authSlice'
import { useDispatch } from 'react-redux'
import { Link } from './components/link'
// Bootstrap
import Navbar from 'react-bootstrap/Navbar'
import Container from 'react-bootstrap/Container'
import Nav from 'react-bootstrap/Nav'

export function Header() {
  // Dispatch state events with async middleware thunks
  const dispatch = useDispatch();

  return (
    <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark">
      <Container>
      <Link className="navbar-brand" href="/">VOQUIS</Link>
      <Navbar.Toggle aria-controls="responsive-navbar-nav" />
      <Navbar.Collapse id="responsive-navbar-nav">
        <Nav className="me-auto">
          <Link href="/projects">Projects</Link>
          <Link href="/invoices">Invoices</Link>
        </Nav>
        <Nav>
          <Nav.Link onClick={() => dispatch(logout())}>
            Log out
          </Nav.Link>
        </Nav>
      </Navbar.Collapse>
      </Container>
    </Navbar>
  )
}
