import React from 'react'
// Redux
import { useDispatch, useSelector } from 'react-redux'
import { login, checkCurrentUser } from '../authSlice'
// Bootstrap
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
// Formik
import { useFormik } from 'formik'
import FormGroup from 'react-bootstrap/esm/FormGroup'

export function Login() {
  // Dispatch state events with async middleware thunks
  const dispatch = useDispatch()

  // Perform a cookie-based login check in case the page was reloaded and the session was destroyed
  dispatch(checkCurrentUser())

  // Get auth form submitting state from state
  const submitting = useSelector((state) => state.auth.submitting)

  // Prepare form validators
  const validate = values => {
    const errors = {};
    if (!values.username) {
      errors.username = 'Required';
    }

    if (!values.password) {
      errors.password = 'Required';
    }

    return errors;
  };

  // Set up form handling
  const formik = useFormik({
    initialValues: {
    },
    validate: validate,
    onSubmit: values => {
      dispatch(login(values))
    },
  });

  return(
    <Form noValidate onSubmit={formik.handleSubmit}>
      <Form.Group className="mb-3" controlId="email">
        <Form.Label>Username</Form.Label>
        <Form.Control
          name="username"
          type="email"
          placeholder="email"
          onChange={formik.handleChange}
        />
        {formik.errors.username ? <div>{formik.errors.username}</div> : null}
      </Form.Group>
      <Form.Group className="mb-3" controlId="password">
        <Form.Label>Password</Form.Label>
        <Form.Control
          name="password"
          type="password"
          placeholder="password"
          onChange={formik.handleChange}
          />
        {formik.errors.password ? <div>{formik.errors.password}</div> : null}
      </Form.Group>
      <FormGroup>
        <Button
          variant="primary"
          type="submit"
          disabled={submitting}
        >
          { submitting ? 'Logging in...' : 'Log in' }
        </Button>
      </FormGroup>
    </Form>
  )
}
